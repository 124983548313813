.date-range .react-datepicker-wrapper {
  display: flex;
  flex: 1;
  max-width: 160px;
}

.date-range .react-datepicker__input-container {
  width: 100%;
}

@media (max-width: 575px) {
  .date-range .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.rc-picker-input input{
  eight: auto;
  padding: 0.4375rem 0.75rem;
  font-size: .8125rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color,box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: box-shadow 250ms cubic-bezier(.27,.01,.38,1.06),border 250ms cubic-bezier(.27,.01,.38,1.06);
  padding: 0.8125rem 0.8125rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.375rem;
}
.rc-picker-range-separator{
  width: 100px;
}
.rc-picker-focused{
  border: none !important;
}
.rc-picker-range{
  width: 100%;
}

.fui-dropzone-root label, .files-ui-footer, .files-ui-header span{
  font-size: 12px !important;
}
